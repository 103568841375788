import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import './FieldSamples.css';

function FieldSample(
  fieldSample,
  testsAssigned,
  tests,
  renderSampleLabelAndValue,
  setSampleTest,
  borehole,
) {
  const selectOptions = [];
  tests.forEach((test) => {
    selectOptions.push({ value: test.id, label: test.officialName });
  });

  // eslint-disable-next-line camelcase
  const {
    depthTop,
    depthBottom,
    bag,
    jar,
    vial,
    sample,
    ec,
    ova,
    comment,
  } = fieldSample;

  const onListChange = (event, newValue) => {
    // eslint-disable-next-line no-unused-vars
    const temp = [
      ...newValue.filter((option) => !tests.includes(option)),
    ];
    setSampleTest(
      temp,
      borehole.id || borehole.borehole,
      depthTop,
      depthBottom,
    );
  };

  return (
    <div className="fieldSampleItem" key={depthTop}>
      <div className="fieldSampleDataPoint">
        <span className="fieldSampleLabel">Name:</span>
        <span className="fieldSampleHeader">{sample}</span>
      </div>
      <div className="fieldSampleContents">
        <div className="fieldSampleRow">
          {renderSampleLabelAndValue('Top', depthTop)}
          {renderSampleLabelAndValue('Bottom', depthBottom)}
          {renderSampleLabelAndValue('EC (dS/m)', ec)}
        </div>
        <div className="fieldSampleRow">
          {renderSampleLabelAndValue('VOC (ppm)', ova)}
          {renderSampleLabelAndValue('Bag', bag)}
          {renderSampleLabelAndValue('Jar', jar)}
          {renderSampleLabelAndValue('Vial', vial)}
        </div>
        <span>
          {renderSampleLabelAndValue('Comment', comment)}
        </span>
        <div className="fieldSampleControlArea">
          <span className="fieldSampleLabel">Tests to Use:</span>
          <Autocomplete
            multiple
            id="testsToUse"
            options={selectOptions}
            getOptionLabel={((option) => option.label)}
            defaultValue={[]}
            onChange={(event, newValue) => onListChange(event, newValue)}
            value={testsAssigned}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="standard"
                label="Tests to use"
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}

function FieldSamples({
  fieldSampleList,
  tests,
  setSampleTest,
  borehole,
  testsForSamples,
}) {
  const renderSampleLabelAndValue = (label, value) => (
    <div className="fieldSampleDataPoint">
      <span className="fieldSampleLabel">
        {label}
        :
      </span>
      <span className="fieldSampleValue">{value || 'N/A'}</span>
    </div>
  );

  const getTestsById = (ids) => {
    const filteredTests = tests.filter((test) => ids.includes(test.id.toString()));
    const formattedTests = filteredTests.map((test) => (
      { value: test.id, label: test.officialName }
    ));
    return formattedTests;
  };

  const getTestsAssigned = (fieldSample) => {
    const matchedT4S = testsForSamples.findIndex((testsForSample) => ((testsForSample.boreholeId
      && testsForSample.boreholeId === borehole.id)
      || (testsForSample.boreholeId === borehole.borehole))
      && testsForSample.top === fieldSample.depthTop
      && testsForSample.bottom === fieldSample.depthBottom);

    if (matchedT4S === -1) {
      return [];
    }

    const testsById = getTestsById(testsForSamples[matchedT4S].testList.split(','));

    return testsById;
  };

  if (!fieldSampleList) return null;

  return (
    <div className="FieldSamples">
      {fieldSampleList.map((fieldSample) => (
        FieldSample(
          fieldSample,
          getTestsAssigned(fieldSample),
          tests,
          renderSampleLabelAndValue,
          setSampleTest,
          borehole,
        )))}
    </div>
  );
}

FieldSamples.propTypes = {
  fieldSampleList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tests: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSampleTest: PropTypes.func.isRequired,
  borehole: PropTypes.shape({
    id: PropTypes.string,
    borehole: PropTypes.string,
  }).isRequired,
  testsForSamples: PropTypes.arrayOf(PropTypes.shape({
    testList: PropTypes.string,
  })).isRequired,
};

export default FieldSamples;
