/**
 * A series of dropdowns for describing the qualities of borehole intervals.
 * Can use one of two schemas. USCS or CSSC.
 */
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

import useIntervalDescriptionFormProvider from '../../../hooks/useIntervalDescriptionFormProvider';

import {
  IntervalDescriptors, USCDescriptors, Custom1Descriptors, SchemaType,
} from '../../../assets/static/models';
import NewMottle from '../mottles/NewMottle';
import MottleList from '../mottles/MottleListComponent';

function SchemaSwitch({
  schemaType,
  fragmentModifierDescriptors,
  fragmentAdjectiveDescriptors,
  primarySizeDescriptors,
  secondaryProportionDescriptors,
  tertiaryProportionDescriptors,
  gradingDescriptors,
  angularityDescriptors,
  plasticityDescriptors,
  consistencyDescriptors,
  consistencyCustom1Descriptors,
  custom1SoilModifierDescriptors,
}) {
  switch (schemaType) {
    case 'schema_cssc':
      return (
        <SchemaCssc
          fragmentModifierDescriptors={fragmentModifierDescriptors}
          fragmentAdjectiveDescriptors={fragmentAdjectiveDescriptors}
        />
      );
    case 'schema_uscs':
      return (
        <SchemaUscu
          primarySizeDescriptors={primarySizeDescriptors}
          secondaryProportionDescriptors={secondaryProportionDescriptors}
          tertiaryProportionDescriptors={tertiaryProportionDescriptors}
          gradingDescriptors={gradingDescriptors}
          angularityDescriptors={angularityDescriptors}
          plasticityDescriptors={plasticityDescriptors}
          consistencyDescriptors={consistencyDescriptors}
        />
      );
    case 'schema_custom1':
      return (
        <SchemaCustom1
          consistencyCustom1Descriptors={consistencyCustom1Descriptors}
          custom1SoilModifierDescriptors={custom1SoilModifierDescriptors}
        />
      );
    default:
      return (
        <SchemaCssc
          fragmentModifierDescriptors={fragmentModifierDescriptors}
          fragmentAdjectiveDescriptors={fragmentAdjectiveDescriptors}
        />
      );
  }
}

SchemaSwitch.propTypes = {
  schemaType: PropTypes.string.isRequired,
  fragmentModifierDescriptors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fragmentAdjectiveDescriptors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  primarySizeDescriptors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  secondaryProportionDescriptors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tertiaryProportionDescriptors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  gradingDescriptors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  angularityDescriptors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  plasticityDescriptors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  consistencyDescriptors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  consistencyCustom1Descriptors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  custom1SoilModifierDescriptors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

function DescriptorDropdown(props) {
  const {
    name,
    label,
    items,
    required,
  } = props;
  const {
    changeForm,
    getFormValue,
  } = useIntervalDescriptionFormProvider();
  return (
    <>
      <InputLabel>{label}</InputLabel>
      <Select
        className="selectFullWidth"
        value={getFormValue(name)}
        required={required}
        onChange={(event) => changeForm(name, event.target.value)}
      >
        <MenuItem value="N/A">N/A</MenuItem>
        {items.map((texture) => (
          <MenuItem key={texture} value={texture}>
            {texture}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

DescriptorDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
};

function SchemaCssc({
  fragmentModifierDescriptors,
  fragmentAdjectiveDescriptors,
}) {
  const [editIndex, setEditIndex] = useState(null);
  return (
    <div>
      <Row>
        <hr />
        <Col xs={12} sm={6} md={6}>
          <DescriptorDropdown items={IntervalDescriptors.fragment_texture} name="fragmentTexture" label="Texture" required="true" />
          <DescriptorDropdown items={IntervalDescriptors.fragment_shape} name="fragmentShape" label="Shape" />
          <DescriptorDropdown items={IntervalDescriptors.structure} name="structure" label="Structure" />
          <DescriptorDropdown items={IntervalDescriptors.soil_horizon} name="soilHorizon" label="Soil Horizon" />
        </Col>
        <Col xs={12} sm={6} md={6}>
          <DescriptorDropdown items={IntervalDescriptors.fragment_fragment} name="fragmentFragment" label="Fragment" />
          <DescriptorDropdown items={fragmentModifierDescriptors} name="modifier" label="Modifier" />
          <DescriptorDropdown items={fragmentAdjectiveDescriptors} name="fragmentAdjective" label="Adjective" />
        </Col>
      </Row>
      <Row>
        <hr />
        <Col xs={12} sm={12} md={12}>
          <NewMottle
            editIndex={editIndex}
            setEditIndex={setEditIndex}
          />
          <MottleList
            editIndex={editIndex}
            setEditIndex={setEditIndex}
          />
        </Col>
      </Row>
      <Row>
        <hr />
        <Col xs={12} sm={12} md={2}>Other</Col>
        <Col xs={12} sm={6} md={5}>
          <DescriptorDropdown items={IntervalDescriptors.deposition} name="deposition" label="Deposition" />
          <DescriptorDropdown items={IntervalDescriptors.moisture} name="moisture" label="Moisture" />
        </Col>
        <Col xs={12} sm={6} md={5}>
          <DescriptorDropdown items={IntervalDescriptors.plasticity} name="plasticity" label="Plasticity" />
          <DescriptorDropdown items={IntervalDescriptors.consistence} name="consistence" label="Consistence" />
        </Col>
      </Row>
      <Row>
        <hr />
        <Col xs={12} sm={12} md={2}>Colour</Col>
        <Col xs={12} sm={4} md={3}><DescriptorDropdown items={IntervalDescriptors.colour1} name="colour1" label="Lightness" /></Col>
        <Col xs={12} sm={4} md={3}><DescriptorDropdown items={IntervalDescriptors.colour2} name="colour2" label="Descriptor" /></Col>
        <Col xs={12} sm={4} md={3}><DescriptorDropdown items={IntervalDescriptors.colour3} name="colour3" label="Colour" /></Col>
      </Row>
    </div>
  );
}

SchemaCssc.propTypes = {
  fragmentModifierDescriptors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fragmentAdjectiveDescriptors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

function SchemaUscu({
  primarySizeDescriptors,
  secondaryProportionDescriptors,
  tertiaryProportionDescriptors,
  gradingDescriptors,
  angularityDescriptors,
  plasticityDescriptors,
  consistencyDescriptors,
}) {
  return (
    <div>
      <Row>
        <hr />
        <Col xs={12} sm={4} md={3}>
          <DescriptorDropdown items={USCDescriptors.constituent} name="primary_constituent" label="Primary Constituent" />
          <DescriptorDropdown items={primarySizeDescriptors} name="primary_size_descriptor" label="Primary Particle Size" />
        </Col>
        <Col xs={12} sm={4} md={3}>
          <DescriptorDropdown items={USCDescriptors.constituent} name="secondary_constituent" label="Secondary Constituent" />
          <DescriptorDropdown items={secondaryProportionDescriptors} name="secondary_proportion" label="Secondary Proportion" />
        </Col>
        <Col xs={12} sm={4} md={3}>
          <DescriptorDropdown items={USCDescriptors.constituent} name="tertiary_constituent" label="Tertiary Constituent" />
          <DescriptorDropdown items={tertiaryProportionDescriptors} name="tertiary_proportion" label="Tertiary Proportion" />
        </Col>
      </Row>
      <Row>
        <hr />
        <Col xs={12} sm={4} md={3}>
          <DescriptorDropdown items={gradingDescriptors} name="grading" label="Grading" />
          <DescriptorDropdown items={angularityDescriptors} name="angularity" label="Angularity" />
        </Col>
        <Col xs={12} sm={4} md={3}>
          <DescriptorDropdown items={plasticityDescriptors} name="plasticity" label="Plasticity" />
          <DescriptorDropdown items={USCDescriptors.colour} name="colour" label="Colour" />
        </Col>
        <Col xs={12} sm={4} md={3}>
          <DescriptorDropdown items={USCDescriptors.odour} name="odour" label="Odour" />
        </Col>
      </Row>
      <Row>
        <hr />
        <Col xs={12} sm={4} md={3}>
          <DescriptorDropdown items={USCDescriptors.moisture} name="moisture" label="Moisture" />
          <DescriptorDropdown items={consistencyDescriptors} name="consistency" label="Consistency" />
        </Col>
        <Col xs={12} sm={4} md={3}>
          <DescriptorDropdown items={USCDescriptors.primary_soil} name="primary_soil" label="Soil Structure" />
          <DescriptorDropdown items={USCDescriptors.observation} name="observation" label="Observations" />
        </Col>
        <Col xs={12} sm={4} md={3}>
          <DescriptorDropdown items={USCDescriptors.genetic_terms} name="genetic_terms" label="Genetic Terms" />
        </Col>
      </Row>
    </div>
  );
}

SchemaUscu.propTypes = {
  primarySizeDescriptors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  secondaryProportionDescriptors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tertiaryProportionDescriptors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  gradingDescriptors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  angularityDescriptors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  plasticityDescriptors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  consistencyDescriptors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

function SchemaCustom1({
  consistencyCustom1Descriptors,
  custom1SoilModifierDescriptors,
}) {
  return (
    <div>
      <Row>
        <hr />
        <Col xs={12} sm={6} md={5}>
          <DescriptorDropdown items={Custom1Descriptors.texture} name="texture" label="Texture" />
          <DescriptorDropdown items={Custom1Descriptors.structure} name="structure" label="Structure" />
          <DescriptorDropdown items={Custom1Descriptors.colour} name="colour" label="Colour" />
        </Col>
        <Col xs={12} sm={6} md={5}>
          <DescriptorDropdown items={Custom1Descriptors.moisture} name="moisture" label="Moisture" />
          <DescriptorDropdown items={consistencyCustom1Descriptors} name="consistence" label="Consistence" />
        </Col>
      </Row>
      <Row>
        <hr />
        <Col xs={12} sm={12} md={2}>Mottle</Col>
        <Col xs={12} sm={6} md={5}>
          <DescriptorDropdown items={Custom1Descriptors.mottle_abundance} name="mottleAbundance" label="Abundance" />
          <DescriptorDropdown items={Custom1Descriptors.mottle_size} name="mottleSize" label="Size" />
        </Col>
        <Col xs={12} sm={6} md={5}>
          <DescriptorDropdown items={Custom1Descriptors.mottle_select} name="mottleSelect" label="Select" />
          <DescriptorDropdown items={Custom1Descriptors.mottle_contrast} name="mottleContrast" label="Contrast" />
        </Col>
      </Row>
      <Row>
        <hr />
        <Col xs={12} sm={12} md={2}>Soil</Col>
        <Col xs={12} sm={6} md={5}>
          <DescriptorDropdown items={Custom1Descriptors.soil_fractures} name="soilFractures" label="Fractures" />
          <DescriptorDropdown items={Custom1Descriptors.soil_disturbances} name="soilDisturbances" label="Disturbances" />
        </Col>
        <Col xs={12} sm={6} md={5}>
          <DescriptorDropdown items={Custom1Descriptors.soil_fragments} name="soilFragments" label="Fragments" />
          <DescriptorDropdown items={custom1SoilModifierDescriptors} name="soilFragmentsModifier" label="Modifier" />
        </Col>
      </Row>
    </div>
  );
}

SchemaCustom1.propTypes = {
  consistencyCustom1Descriptors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  custom1SoilModifierDescriptors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

function IntervalDescriptionForm({
  schemaType,
}) {
  const {
    getFormValue,
  } = useIntervalDescriptionFormProvider();

  const fragmentFragment = getFormValue('fragmentFragment');
  const primaryConstituent = getFormValue('fragmentFragment');
  const secondaryConstituent = getFormValue('fragmentFragment');
  const tertiaryConstituent = getFormValue('fragmentFragment');
  const plasticity = getFormValue('fragmentFragment');
  const moisture = getFormValue('moisture');
  const soilFragments = getFormValue('soilFragments');

  /* CSSC */
  let fragmentAdjectiveDescriptors = [];
  switch (fragmentFragment) {
    case 'Gravelly': fragmentAdjectiveDescriptors = IntervalDescriptors.fragment_adjective_gravelly; break;
    case 'Sandy': fragmentAdjectiveDescriptors = IntervalDescriptors.fragment_adjective; break;
    default: fragmentAdjectiveDescriptors = [];
  }

  const fragmentModifierDescriptors = fragmentFragment ? IntervalDescriptors.fragment_modifier : [];

  /* USCS */
  // Primary size descriptors available only if gravel or sand
  let primarySizeDescriptors = [];
  switch (primaryConstituent) {
    case 'Gravel': primarySizeDescriptors = USCDescriptors.primary_size_gravel; break;
    case 'Sand': primarySizeDescriptors = USCDescriptors.primary_size_sand; break;
    default: primarySizeDescriptors = [];
  }

  // Secondary proportion depends on whether or not a secondary constituent has been selected
  const secondaryProportionDescriptors = secondaryConstituent
    ? USCDescriptors.secondary_proportion : [];
  const tertiaryProportionDescriptors = tertiaryConstituent
    ? USCDescriptors.secondary_proportion : [];

  // Grading descriptors are shown if the primary consttueent is sand
  const gradingDescriptors = primaryConstituent === 'Sand' ? USCDescriptors.grading : [];

  // Angularity descriptors are available only if the primary constituent is either gravel or sand
  const angularityDescriptors = primaryConstituent === 'Sand'
    || primaryConstituent === 'Gravel' ? USCDescriptors.angularity : [];

  // Plasticity descriptors are available only if clay is chosen as primary or secondary constituent
  const plasticityDescriptors = (primaryConstituent === 'Clay' || secondaryConstituent === 'Clay')
    ? USCDescriptors.plasticity : [];

  // Consistency descriptors change based on primary constituent and plasticity
  let consistencyDescriptors = [];
  if ((primaryConstituent === 'Clay' || secondaryConstituent === 'Clay')
  && (plasticity === 'Low Plastic' || plasticity === 'Medium Plastic' || plasticity === 'High Plastic')) {
    consistencyDescriptors = USCDescriptors.consistency_plastic;
  } else if (primaryConstituent === 'Gravel' || primaryConstituent === 'Sand' || primaryConstituent === 'Silt') {
    consistencyDescriptors = USCDescriptors.consistency_gss;
  }

  /* Custom 1 */
  let consistencyCustom1Descriptors = [];
  switch (moisture) {
    case 'Dry': consistencyCustom1Descriptors = Custom1Descriptors.dry_consistency; break;
    case 'Moist': consistencyCustom1Descriptors = Custom1Descriptors.moist_consistency; break;
    case 'Wet': consistencyCustom1Descriptors = Custom1Descriptors.wet_consistency; break;
    default: consistencyCustom1Descriptors = [];
  }

  const custom1SoilModifierDescriptors = soilFragments
    ? Custom1Descriptors.soil_fragments_modifier : [];

  return (
    <Grid className="nested-grid">
      <SchemaSwitch
        schemaType={schemaType}
        fragmentModifierDescriptors={fragmentModifierDescriptors}
        fragmentAdjectiveDescriptors={fragmentAdjectiveDescriptors}
        primarySizeDescriptors={primarySizeDescriptors}
        secondaryProportionDescriptors={secondaryProportionDescriptors}
        tertiaryProportionDescriptors={tertiaryProportionDescriptors}
        gradingDescriptors={gradingDescriptors}
        angularityDescriptors={angularityDescriptors}
        plasticityDescriptors={plasticityDescriptors}
        consistencyDescriptors={consistencyDescriptors}
        consistencyCustom1Descriptors={consistencyCustom1Descriptors}
        custom1SoilModifierDescriptors={custom1SoilModifierDescriptors}
      />
    </Grid>
  );
}

IntervalDescriptionForm.propTypes = {
  schemaType: PropTypes.oneOf(SchemaType),
};

IntervalDescriptionForm.defaultProps = {
  schemaType: 'schema_cssc',
};

export default IntervalDescriptionForm;
