import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const EditFormContext = createContext({
  getIsInFormEdit: () => 'context not defined',
  toggleEdit: () => 'context not defined',
});

function EditFormProvider({ children }) {
  const [isEdit, setIsEdit] = useState(false);

  const getIsInFormEdit = () => isEdit;
  const toggleEdit = (isEditFlag) => setIsEdit(isEditFlag);

  return (
    <EditFormContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        getIsInFormEdit,
        toggleEdit,
      }}
    >
      {children}
    </EditFormContext.Provider>
  );
}

EditFormProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EditFormProvider;
